import { SyntheticEvent, Dispatch, SetStateAction } from 'react';
import { format } from 'date-fns';
import { excludedNodeNames } from 'constants/cardExpandExcludedNodeNames';
import { PADDING_BETWEEN_CARDS } from 'constants/cards';

export const getNodeDatasetUri = (node: HTMLElement): string | null => {
  const { dataset } = node;
  const parentNode = node.parentNode as HTMLElement;

  if (!dataset.uri) {
    return node.parentNode ? getNodeDatasetUri(parentNode) : null;
  }

  return dataset.uri;
};

export const handleMenuTabSelect = (props: {
  uri: string;
  navigate: (uri: string) => void;
  changeTab?: (index: number) => void;
  setIsSearchUsed?: (value: boolean) => void;
}) => (_tabIndex: number, _lastIndex: number, event: Event): void => {
  const target = event.target as HTMLElement;

  const uri = getNodeDatasetUri(target) || '';

  props.setIsSearchUsed?.(false);

  props.changeTab?.(_tabIndex || 0);

  if (uri !== props.uri) {
    props.navigate(uri);
  } else if (_tabIndex !== _lastIndex) {
    props.navigate('/');

    setTimeout(() => window.location.replace(uri), 0);
  }
};

export const getCardFormattedDate = (updatedAt: string): string =>
  format(new Date(updatedAt), 'MMMM dd, yyyy');

export const isHyperlinkNode = (node: HTMLElement): boolean => {
  if (excludedNodeNames.includes(node.nodeName.toLowerCase())) {
    return true;
  }
  if (node.parentNode) {
    return isHyperlinkNode(node.parentNode as HTMLElement);
  }

  return false;
};

const handler = (
  event: SyntheticEvent,
  expanded: boolean,
  setExpanded: Dispatch<SetStateAction<boolean>>,
) => {
  const node = event.target as HTMLElement;

  if (!isHyperlinkNode(node)) {
    setExpanded(!expanded);
  }
};

export const createCardExpandHandler = (
  setExpanded: Dispatch<SetStateAction<boolean>>,
  expanded: boolean,
) => (event: SyntheticEvent): void => {
  const shouldContentBeClosed = !(
    event.currentTarget.querySelector('[data-close-content]')?.contains(event.target as Element) &&
    expanded
  );

  if (shouldContentBeClosed) {
    handler(event, expanded, setExpanded);
  }
};

export const closeCard = ({ currentTarget }: SyntheticEvent, expanded: boolean): void => {
  const heightStickyHeader = (document.querySelector('.sticky-inner-wrapper') as HTMLElement)
    ?.offsetHeight;

  if (expanded && currentTarget.getBoundingClientRect().top <= heightStickyHeader) {
    const parentNode = currentTarget.parentNode as HTMLElement;
    const scrollValue = parentNode.offsetTop - heightStickyHeader - PADDING_BETWEEN_CARDS;

    window.scroll(0, scrollValue);
  }
};

export const getName = (title: string): string =>
  encodeURI(title.replace(/[^a-z0-9]/gi, '-').replace(/-{2,}/g, '-'));

export const getNumberWithOrdinal = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;

  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
